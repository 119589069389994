import { render, staticRenderFns } from "./AreasOcupadasBuffers.vue?vue&type=template&id=1303ece7&scoped=true"
import script from "./AreasOcupadasBuffers.vue?vue&type=script&lang=js"
export * from "./AreasOcupadasBuffers.vue?vue&type=script&lang=js"
import style0 from "./AreasOcupadasBuffers.vue?vue&type=style&index=0&id=1303ece7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1303ece7",
  null
  
)

export default component.exports
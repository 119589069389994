import { render, staticRenderFns } from "./ISs.vue?vue&type=template&id=19ea4fda&scoped=true"
import script from "./ISs.vue?vue&type=script&lang=js"
export * from "./ISs.vue?vue&type=script&lang=js"
import style0 from "./ISs.vue?vue&type=style&index=0&id=19ea4fda&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19ea4fda",
  null
  
)

export default component.exports
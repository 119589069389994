var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{attrs:{"id":"headernav"}},[_c('v-row',{staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"2","md":"1"}},[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_c('v-img',{attrs:{"max-width":"70px","src":require("@/assets/icons/urbverde-logo-icon.png")}})],1)],1),_c('v-col',{class:{ highlight: _vm.highlightSearch },attrs:{"cols":"10","md":"5"}},[_c('v-autocomplete',{staticClass:"mt-5",attrs:{"color":"#01dc82","items":_vm.municipios,"label":"Selecione outro município"},model:{value:(_vm.municipioSelected),callback:function ($$v) {_vm.municipioSelected=$$v},expression:"municipioSelected"}})],1),_c('v-col',{staticClass:"headernav__categories",attrs:{"cols":"12","md":"6"}},[_c('router-link',{staticClass:"layers__box_category",class:{ category__active: _vm.activeRoute == 'pracasparques', },attrs:{"to":{
        name: 'Mapa',
        params: {
          id: this.$route.params.id,
          ano: this.$route.params.ano || 2016,
          escala: this.$route.params.escala,
          categoria: 'pracasparques',
        },
      }}},[_c('a',[_c('span',[_vm._v("Praças e Parques")])])]),_c('router-link',{staticClass:"layers__box_category",class:{ category__active: _vm.activeRoute == 'vegetacao', },attrs:{"to":{
        name: 'Mapa',
        params: {
          id: this.$route.params.id,
          ano: this.$route.params.ano || 2016,
          escala: this.$route.params.escala,
          categoria: 'vegetacao',
        },
      }}},[_c('a',[_c('span',[_vm._v("Vegetação")])])]),_c('router-link',{staticClass:"layers__box_category",class:{ category__active: _vm.activeRoute == 'temperatura', },attrs:{"to":{
        name: 'Mapa',
        params: {
          id: this.$route.params.id,
          ano: this.$route.params.ano || 2016,
          escala: this.$route.params.escala,
          categoria: 'temperatura',
        },
      }}},[_c('a',[_c('span',[_vm._v("Temperatura")])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }